import { defineRouting } from 'next-intl/routing'
import { getLocale } from 'next-intl/server'

export const locales = ['en', 'es'] as const

export type Locales = (typeof locales)[number]

export enum ContentfulLocales {
  en = 'en-US',
  es = 'es-419',
}

export const getContentfulLocale = async () => {
  const locale = await getLocale()
  if (locale in ContentfulLocales) {
    return ContentfulLocales[locale as Locales]
  }
  return ContentfulLocales.en
}

export const routing = defineRouting({
  locales,
  defaultLocale: 'en',
  localePrefix: 'as-needed',
})
