'use client'
import { type Ref, forwardRef, type ComponentPropsWithRef, createElement } from 'react'
import clsx from 'clsx'

// TODO: only underline the text; not the icon

export interface NavLinkProps extends ComponentPropsWithRef<'a'> {
  as?: 'a' | 'button'
  active?: boolean
}

const NavLink = (
  { as = 'a', active = false, className, ...props }: NavLinkProps,
  ref: Ref<HTMLAnchorElement>
) => {
  return createElement(as, {
    ...props,
    ref,
    className: clsx(
      'transition-colors after:ease-in after:origin-center',
      'cursor-pointer',
      'font-sans font-medium',
      'inline-block',
      'outline outline-0 ring-0 ring-offset-0',
      'after:block after:transform after:transition-all after:h-[3px] after:bg-primary',
      !active &&
        'after:scale-x-90 after:opacity-0 hover:after:opacity-50 hover:after:scale-x-100 focus-visible:after:scale-x-100 focus-visible:after:opacity-50',
      active && 'after:scale-x-100 after:opacity-100',
      className
    ),
  })
}

export default forwardRef(NavLink)
