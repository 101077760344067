'use client'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { usePathname } from '@i18n/navigation'
import { useCallback, useEffect, useState } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import { AnalyticsLink } from '@components/analytics'
import Backdrop from '@components/backdrop'
import type { Menu as IMenu } from '@lib/types'
import { Button, Icon, Logo } from '@shc/ui'
import NavLink from '@components/nav-link'
import MenuDrawer from '@components/menu-drawer'
import SearchTakeover from '@components/search-takeover'
import Superheader from '@components/superheader'
import { useLocale, useTranslations } from 'next-intl'

export interface HeaderProps extends ComponentPropsWithoutRef<any> {
  menu: IMenu
  menuDrawer?: IMenu
  menuDrawerFeatured?: IMenu
  menuSearch?: IMenu
  menuSuperheader?: IMenu
  showMenuButton?: 'mobile only' | 'always'
  addMenuToMenuDrawer?: boolean
  logoLink?: string
  showSearch?: boolean
  searchUrl?: string
  baseSearchFilter?: string
  cta?: JSX.Element
  drawerHeader?: JSX.Element
  drawerChildren?: JSX.Element
}

const Header = ({
  menu,
  menuDrawer = [],
  menuDrawerFeatured = [],
  menuSearch = [],
  menuSuperheader = [],
  showMenuButton = 'always',
  addMenuToMenuDrawer = false,
  logoLink = '/',
  showSearch = true,
  searchUrl,
  baseSearchFilter,
  cta,
  drawerHeader,
  drawerChildren,
  className,
  children,
  ...props
}: HeaderProps) => {
  const [centerNavFlyout, setCenterNavFlyout] = useState(-1)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSearchTakeoverVisible, setIsSearchTakeoverVisible] = useState(false)

  const activePathname = usePathname()

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setCenterNavFlyout(-1)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  const searchClick = (): void => {
    setIsSearchTakeoverVisible(true)
  }

  // CSS helpers

  const getBackgroundClass = (centerNavFlyout: number): string => {
    let bgClass = ''
    if (centerNavFlyout === -1) {
      bgClass = 'bg-white'
    }
    if (centerNavFlyout > -1) {
      bgClass = 'bg-white lg:bg-primary-50'
      // adding a drop shadow when there is no submenu
      if (menu[centerNavFlyout][1].length === 0) {
        bgClass = bgClass + 'drop-shadow-sm-bottom'
      }
    }
    return bgClass
  }

  const locale = useLocale()
  const translate = useTranslations('Header')

  return (
    <>
      {children}
      <Superheader menuSuperheader={menuSuperheader} />

      <header
        id="shc-main-header"
        className={clsx(
          'sticky top-0 transition-colors z-20 drop-shadow-sm-bottom',
          getBackgroundClass(centerNavFlyout),
          className
        )}
        {...props}>
        {/* HeaderWrap */}
        <div className="container flex flex-row justify-between h-16 mx-auto gap-x-5">
          {/* LeftNav */}
          <div className="flex flex-row items-center justify-start gap-x-8 basis-1/3 lg:basis-auto">
            <Button
              id="menu-drawer-button"
              aria-label="Open menu"
              variant="flat"
              size="sm"
              width="auto"
              onClick={() => {
                setIsMenuOpen(true)
              }}
              className={clsx(
                'text-gray-700 !px-0',
                showMenuButton === 'mobile only' && 'lg:hidden'
              )}>
              <Icon
                icon={['fak', locale === 'es' ? 'menu-es' : 'menu']}
                style={{ width: 40, height: 26 }}
              />
            </Button>
            <AnalyticsLink
              href={logoLink}
              aria-label="Sharp logo"
              className="hidden p-2 lg:inline lg:pl-0"
              snowplow={{
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: 'Home',
                    navigation_level: 1,
                    navigation_subject: 'Logo',
                    navigation_url: logoLink,
                  },
                },
                contexts: [
                  {
                    name: 'section',
                    data: { section_name: 'header' },
                  },
                ],
              }}
              data-testid="logo-desktop">
              <Logo aria-hidden="true" className="h-8" color="brand" />
            </AnalyticsLink>
          </div>

          {/* LogoMobile */}
          <div className="flex flex-row items-center justify-center lg:hidden basis-1/3">
            <AnalyticsLink
              href={logoLink}
              aria-label="Sharp logo"
              className="p-2"
              data-testid="logo-mobile"
              snowplow={{
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: 'Home',
                    navigation_level: 1,
                    navigation_subject: 'Logo',
                    navigation_url: logoLink,
                  },
                },
                contexts: [
                  {
                    name: 'section',
                    data: { section_name: 'header' },
                  },
                ],
              }}>
              <Logo aria-hidden="true" className="h-7" color="brand" />
            </AnalyticsLink>
          </div>

          {/* CenterNav */}
          <ul
            className="flex-row items-center hidden lg:flex"
            onMouseLeave={() => setCenterNavFlyout(-1)}>
            {menu.map(([link1, children1], idx1) => (
              <li key={idx1} className={clsx(centerNavFlyout === idx1 && 'bg-white')}>
                {children1.length === 0 && (
                  <AnalyticsLink
                    as={NavLink}
                    asPassthru="a"
                    href={link1.route}
                    target={link1.isInternal ? undefined : '_blank'}
                    rel={link1.isInternal ? undefined : 'noopener noreferrer'}
                    active={link1.route.toLowerCase() === activePathname}
                    aria-current={link1.route.toLowerCase() === activePathname ? 'page' : undefined}
                    className="pt-4.5 pb-4.25 xl:px-8 px-5 text-sm !font-semibold"
                    onMouseOver={() => setCenterNavFlyout(idx1)}
                    snowplow={{
                      event: {
                        name: 'navigation_click',
                        data: {
                          navigation_tree: link1.name,
                          navigation_subject: link1.name,
                          navigation_level: 1,
                          navigation_url: link1.route,
                        },
                      },
                      contexts: [{ name: 'section', data: { section_name: 'header' } }],
                    }}>
                    {link1.name}
                  </AnalyticsLink>
                )}
                {children1.length > 0 && (
                  <NavLink
                    as="button"
                    active={activePathname?.startsWith(link1.route.toLowerCase())}
                    className="pt-4.5 pb-4.25 xl:px-8 px-5 text-sm !font-semibold"
                    onMouseOver={() => setCenterNavFlyout(idx1)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        if (centerNavFlyout === idx1) {
                          setCenterNavFlyout(-1)
                        } else {
                          setCenterNavFlyout(idx1)
                        }
                      }
                    }}
                    aria-haspopup={true}
                    aria-expanded={centerNavFlyout === idx1}>
                    {link1.name}
                  </NavLink>
                )}

                {children1.length > 0 && (
                  <Transition
                    unmount={false} // Do not remove from DOM (for screen readers and performance)
                    className="absolute left-0 right-0 -mt-px origin-top"
                    show={centerNavFlyout === idx1}
                    enter="transition-all ease-out duration-300"
                    enterFrom="scale-y-95"
                    enterTo="scale-y-100"
                    leave="transition-all ease-in duration-200"
                    leaveFrom="scale-y-100"
                    leaveTo="scale-y-90"
                    data-testid={`header-flyout-${idx1}`}>
                    <div
                      className="bg-white h-fit drop-shadow-md"
                      onMouseOver={() => setCenterNavFlyout(idx1)}
                      onFocus={() => setCenterNavFlyout(idx1)}
                      onMouseLeave={() => setCenterNavFlyout(-1)}>
                      <div
                        className={clsx(
                          'container',
                          'flex flex-row flex-wrap pb-6 mx-auto',
                          children1.some((child) => child.length > 1) && 'gap-x-15',
                          'md:!px-[32px]',
                          'xl:!px-[72px]'
                        )}>
                        {children1.map(([link2, children2], idx2) => (
                          <div
                            key={idx2}
                            className={clsx(
                              'mt-8',
                              children1.length === 1 && 'basis-full',
                              children1.length > 1 && idx2 <= 3 && 'basis-[calc(25%_-_3.75rem)]',
                              children1.length > 1 && idx2 > 3 && 'basis-full border-t pt-8'
                            )}>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <AnalyticsLink
                              as="a"
                              href={link2.route}
                              target={link2.isInternal ? undefined : '_blank'}
                              rel={link2.isInternal ? undefined : 'noopener noreferrer'}
                              className="text-lg font-bold lg:text-xl text-primary hover:text-primary-800 outline-primary"
                              snowplow={{
                                event: {
                                  name: 'navigation_click',
                                  data: {
                                    navigation_tree: `${link1.name} > ${link2.name}`,
                                    navigation_level: 2,
                                    navigation_subject: link2.name,
                                    navigation_url: link2.route,
                                  },
                                },
                                contexts: [{ name: 'section', data: { section_name: 'header' } }],
                              }}>
                              {link2.icon !== undefined && (
                                <Icon icon={link2.icon} className="pr-1.5" />
                              )}
                              {link2.name}
                            </AnalyticsLink>
                            {children2.length > 0 && (
                              <ul
                                className={clsx(
                                  'mt-4 list-none flex flex-col flex-wrap justify-between items-start content-start gap-x-20',
                                  (children1.length === 1 || idx2 > 3) &&
                                    'max-h-[180px] xl:max-h-[140px]'
                                )}>
                                {children2.map(([link3], idx3) => (
                                  <li
                                    key={idx3}
                                    className={clsx(idx2 > 3 && 'w-[calc(25%_-_5rem)]', 'pb-2')}>
                                    <AnalyticsLink
                                      as={NavLink}
                                      className="text-sm !font-semibold"
                                      href={link3.route}
                                      target={link3.isInternal ? undefined : '_blank'}
                                      rel={link3.isInternal ? undefined : 'noopener noreferrer'}
                                      active={link3.route.toLowerCase() === activePathname}
                                      aria-current={
                                        link3.route.toLowerCase() === activePathname
                                          ? 'page'
                                          : undefined
                                      }
                                      snowplow={{
                                        event: {
                                          name: 'navigation_click',
                                          data: {
                                            navigation_tree: `${link1.name} > ${link2.name} > ${link3.name}`,
                                            navigation_level: 3,
                                            navigation_subject: link3.name,
                                            navigation_url: link3.route,
                                          },
                                        },
                                        contexts: [
                                          { name: 'section', data: { section_name: 'header' } },
                                        ],
                                      }}>
                                      {link3.icon !== undefined && (
                                        <Icon icon={link3.icon} className="pr-1.5" />
                                      )}
                                      {link3.name}
                                    </AnalyticsLink>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Transition>
                )}
              </li>
            ))}
          </ul>
          {/* end: CenterNav */}

          {/* RightNav */}
          <div className="flex flex-row items-center justify-end gap-x-10 xl:gap-x-15 basis-1/3 lg:basis-auto">
            {/* Mobile search button */}
            {showSearch && (
              <>
                <AnalyticsLink
                  as={Button}
                  size="sm"
                  variant="flat"
                  name="Search"
                  width="auto"
                  className="md:hidden"
                  aria-label="Search"
                  onClick={searchClick}
                  snowplow={{
                    event: {
                      name: 'navigation_click',
                      data: {
                        navigation_tree: 'Search',
                        navigation_level: 1,
                        navigation_subject: 'Search',
                      },
                    },
                    contexts: [
                      {
                        name: 'section',
                        data: { section_name: 'header' },
                      },
                      {
                        name: 'modal',
                        data: {
                          modal_name: 'Search takeover',
                        },
                      },
                    ],
                  }}>
                  <Icon icon="magnifying-glass" className="h-5" />
                </AnalyticsLink>

                <AnalyticsLink
                  as="button"
                  asPassthru={NavLink}
                  className="hidden md:block text-sm !font-semibold"
                  onClick={searchClick}
                  data-testid="search-desktop"
                  snowplow={{
                    event: {
                      name: 'navigation_click',
                      data: {
                        navigation_tree: 'Search',
                        navigation_level: 1,
                        navigation_subject: 'Search',
                      },
                    },
                    contexts: [
                      {
                        name: 'section',
                        data: { section_name: 'header' },
                      },
                      {
                        name: 'modal',
                        data: {
                          modal_name: 'Search takeover',
                        },
                      },
                    ],
                  }}>
                  <Icon icon="magnifying-glass" />
                  <span className="hidden pl-1 md:inline">{translate('search')}</span>
                </AnalyticsLink>
              </>
            )}

            {!!cta && cta}
          </div>
          {/* end: RightNav */}
        </div>
        {/* end:HeaderWrap */}
      </header>
      <Transition
        unmount={false} // Do not remove from DOM (for screen readers and performance)
        className="fixed inset-0 z-10"
        show={centerNavFlyout > -1 && menu[centerNavFlyout][1].length > 0}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Backdrop}
        onClick={() => setCenterNavFlyout(-1)}
      />

      <MenuDrawer
        menu={addMenuToMenuDrawer ? menu.concat(menuDrawer) : menuDrawer}
        menuFeatured={menuDrawerFeatured}
        menuSuperheader={menuSuperheader}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        activePathname={activePathname}
        header={drawerHeader}>
        {drawerChildren}
      </MenuDrawer>

      <SearchTakeover
        menu={menuSearch}
        isVisible={isSearchTakeoverVisible}
        setIsVisible={setIsSearchTakeoverVisible}
        activePathname={activePathname}
        searchUrl={searchUrl}
        baseSearchFilter={baseSearchFilter}
      />
    </>
  )
}

export default Header
