import { cloneElement, type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'

export interface FieldGroupProps extends ComponentPropsWithoutRef<'div'> {
  button: JSX.Element
  children: JSX.Element
  buttonPosition?: 'start' | 'end'
}

const FieldGroup = ({
  children,
  button,
  buttonPosition = 'end',
  className,
  ...props
}: FieldGroupProps) => {
  const fieldClassName = clsx(
    children.props.className,
    buttonPosition === 'start' && 'pl-20',
    buttonPosition === 'end' && 'pr-20'
  )

  const buttonClassName = clsx(
    button.props.className,
    'top-0 !absolute before:rounded-none',
    buttonPosition === 'start' && 'left-0 before:rounded-l',
    buttonPosition === 'end' && 'right-0 before:rounded-r'
  )

  return (
    <div {...props} className={clsx('relative', className)}>
      {cloneElement(children, { ...children.props, className: fieldClassName })}
      {cloneElement(button, { ...button.props, className: buttonClassName })}
    </div>
  )
}

export default FieldGroup
