import { type ReadonlyURLSearchParams } from 'next/navigation'
import type { SearchClient } from 'algoliasearch/lite'
import algoliasearch from 'algoliasearch/lite'
import type { SearchBoxConnectorParams } from 'instantsearch.js/es/connectors/search-box/connectSearchBox'
import { history } from 'instantsearch.js/es/lib/routers'
import type { RouterProps } from 'instantsearch.js/es/middlewares/createRouterMiddleware'
import type { IndexUiState, UiState } from 'instantsearch.js/es/types/ui-state'
import isUndefined from 'lodash/isUndefined'
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

const appId: string = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? ''

export const hierarchicalDelimiter = ' > '

export const getSearchDefaultClient = (appKey: string) => algoliasearch(appId, appKey)

export const getSearchClient = (appKey: string): SearchClient => {
  const algoliaClient = getSearchDefaultClient(appKey)
  return {
    ...algoliaClient,
    // Modify default behavior to return no results on initial load (without a query)
    search(queries: any, requestOptions: any) {
      if (queries.every(({ params }: any) => !params.query)) {
        return Promise.resolve({
          results: queries.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return algoliaClient.search(queries, requestOptions)
    },
  }
}

// Basic routing configuration for Algolia InstantSearch where you only have query and page syncing
export const getRouterProps = (indexName: string): RouterProps => {
  const routerProps: RouterProps = {
    router: history({
      cleanUrlOnDispose: false,
    }),
    stateMapping: {
      // eslint-disable-next-line sonarjs/no-identical-functions
      stateToRoute: (uiState: any) => {
        const indexUiState = uiState[indexName]
        return {
          q: indexUiState.query,
          p: indexUiState.page,
        }
      },
      // eslint-disable-next-line sonarjs/no-identical-functions
      routeToState: (routeState: any) => {
        return {
          [indexName]: {
            query: routeState.q,
            page: routeState.p,
          },
        }
      },
    },
  }

  return routerProps
}

export function debounceQueryHook(
  debounceMs: number
): NonNullable<SearchBoxConnectorParams['queryHook']> {
  let timerId: NodeJS.Timeout | undefined = undefined
  return (query, search) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => search(query), debounceMs)
  }
}

/**
 * An opinionated configuration for Find a doc.
 *
 * The deviations from the default Algolia configurations follow:
 *  - versioned ex: ?v=1
 *  - remove the indexname object nesting
 *      default: index[query]=hello
 *      change: q=hello
 *  - flatten the refinementList and hierarchicalMenu object nesting
 *      default: index[refinementList][interests]=cars
 *      change: interests=cars
 *  - use commas for arrays instead of brakets in searchParams
 *      default: a[0]=1&a[1]=2&a[2]=3
 *      change: a=1,2,3
 *  - shortened aliases for common parameters
 *  - keep custom location search parameter between updates.
 *    The default implementation completely replace the search parameters,
 *    for our implementation we need to keep the `l` param if it exists.
 *
 */
export type RouteState = { [x: string]: any }

// Advanced configuration for Algolia InstantSearch where you take into account query, page, sorting, hierarchical menus, and refinement lists
export const getSearchRouter = (indexName: string, router: AppRouterInstance): RouterProps => ({
  stateMapping: {
    /**
     * This function takes the Algolia components internal state and
     * creates an object that is sent to the router.createURL method.
     */
    stateToRoute(uiState: UiState): RouteState {
      const indexUiState = uiState[indexName] ?? {}
      const result: RouteState = {}
      const { query, page, sortBy, hierarchicalMenu = {}, refinementList = {} } = indexUiState
      if (!isUndefined(query)) result.q = query
      if (!isUndefined(page)) result.p = page
      if (!isUndefined(sortBy)) result.s = sortBy.replace(`${indexName}_`, '')

      const hierarchicalMenuFlattened = Object.keys(hierarchicalMenu).reduce<
        Record<string, string | string[] | undefined>
      >((agg, key) => {
        agg[key] = indexUiState?.hierarchicalMenu?.[key]
        return agg
      }, {})

      const refinementListFlattened = Object.keys(refinementList).reduce<
        Record<string, string | string[] | undefined>
      >((agg, key) => {
        agg[key] = indexUiState?.refinementList?.[key]
        return agg
      }, {})

      return {
        ...result,
        ...hierarchicalMenuFlattened,
        ...refinementListFlattened,
      }
    },
    /**
     * Takes the result from router.parseURL and assigns it back
     * to Algolia's components UIState object.
     */
    routeToState(routeState: RouteState) {
      const { q, p, s, v, ...rest } = routeState
      const result: Record<string, any> = {}
      if (!isUndefined(q)) result.query = q
      if (!isUndefined(p)) result.page = p
      if (!isUndefined(s)) result.sortBy = s.startsWith(indexName) ? s : `${indexName}_${s}`

      const restNested = Object.keys(rest).reduce<IndexUiState>((agg, key) => {
        agg.refinementList = agg.refinementList ?? {}
        agg.refinementList[key] = Array.isArray(routeState[key])
          ? routeState[key]
          : routeState[key].split(',')
        return agg
      }, {})

      return {
        [indexName]: {
          ...result,
          ...restNested,
        },
      }
    },
  },
  router: history({
    cleanUrlOnDispose: false,
    writeDelay: 400,
    /**
     * Define our own push logic to play nice with NextJS App Router
     * This is to skip the push with empty routeState on dispose as it would clear params set on a <Link>
     */
    push(url) {
      // Ignoring the type gymnastics because this is a protected property but our usage is safe since checking for truthy
      //@ts-ignore
      if (this.isDisposed) {
        return
      }
      router.push(url, { scroll: false })
    },
    parseURL({ qsModule, location }): RouteState {
      return qsModule.parse(location.search.slice(1), { comma: true })
    },
    createURL({ qsModule, location, routeState }) {
      const { origin, pathname, hash, search } = location
      const paramsToKeep = ['l']

      // grab current query string, remove the trailing `?` and convert to object
      const oldQueryParams = qsModule.parse(search.slice(1), { comma: true }) || {}
      const newQueryParams: Record<string, any> = { ...routeState }

      // if our custom param exists then keep them
      paramsToKeep.forEach((param) => {
        if (oldQueryParams.hasOwnProperty(param)) {
          newQueryParams[param] = oldQueryParams[param]
        }
      })

      // add current router version
      newQueryParams.v = 1
      let newQueryString = ''

      if (Object.keys(newQueryParams).length > 1) {
        newQueryString = qsModule.stringify(newQueryParams, {
          format: 'RFC1738',
          encodeValuesOnly: true,
          arrayFormat: 'comma',
        })
        // add version number in case we need to change the parser
        // without abusing redirects or a proxy
        newQueryString = `?${newQueryString}`
      }

      return `${origin}${pathname}${newQueryString}${hash}`
    },
  }),
})

export const createFilteredParams = (searchParams: ReadonlyURLSearchParams | null) => {
  const paramsToKeep = ['q', 'l', 'v']
  const filteredSearchParams = new URLSearchParams()

  paramsToKeep.forEach((param) => {
    if (searchParams?.has(param)) {
      filteredSearchParams.append(param, searchParams.get(param) as string)
    }
  })

  return filteredSearchParams
}
