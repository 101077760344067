'use client'

import { usePathname } from '@i18n/navigation'
import { Icon } from '@shc/ui'
import { useLocale } from 'next-intl'
import { AnalyticsLink } from '@components/analytics'
import { type SectionContext } from '@hooks/use-analytics'

type IntlToggleProps = {
  section: SectionContext['section_name']
}

const IntlToggle = ({ section }: IntlToggleProps) => {
  const currentLocale = useLocale()
  const pathname = usePathname()

  const isEnglish = currentLocale === 'en'

  const text = isEnglish ? 'Español' : 'English'
  const newPathname = isEnglish ? '/es' + pathname : '/en' + pathname

  return (
    <AnalyticsLink
      role="button"
      href={newPathname}
      className="inline-flex flex-row items-center text-sm"
      noUnderline
      snowplow={{
        event: {
          name: 'navigation_click',
          data: {
            navigation_tree: text,
            navigation_subject: text,
            navigation_level: 1,
            navigation_url: newPathname,
          },
        },
        contexts: [{ name: 'section', data: { section_name: section } }],
      }}>
      <Icon icon="globe-americas" className="text-gray-700 pr-1.5" />
      <span>{text}</span>
    </AnalyticsLink>
  )
}

export default IntlToggle
