import type { Entry } from 'contentful'
import slugify from '@sindresorhus/slugify'
import type { IconName, IconPrefix } from '@shc/ui'
import type {
  TypeIconBrandFields,
  TypeIconDuotoneFields,
  TypeIconFields,
} from '@lib/generated-types'

type IconContentType = 'icon' | 'iconDuotone' | 'iconBrand' | 'iconCustom'

const typeToPrefix: Record<string, IconPrefix> = {
  icon: 'fas',
  iconDuotone: 'fad',
  iconBrand: 'fab',
  iconCustom: 'fak',
}

export const getIcon = (iconName?: string, type?: IconContentType) => {
  if (!iconName) {
    return null
  }
  const name = slugify(iconName)
  if (!type) {
    return name as IconName
  }
  return [typeToPrefix[type] ?? 'fas', name] as [IconPrefix, IconName]
}

export const getIconFromContentful = (
  icon?: Entry<TypeIconFields | TypeIconDuotoneFields | TypeIconBrandFields>
) => {
  if (!icon) return null
  const prefix = typeToPrefix[icon.sys?.contentType?.sys?.id]
  if (!prefix) return null
  const name = slugify(icon.fields.icon)
  return [prefix, name] as [IconPrefix, IconName]
}
